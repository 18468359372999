import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import { StaticQuery, graphql } from 'gatsby';

import Header from './Header';
import Footer from './Footer';
import './layout.css';
import { Colors } from '../theme';

const Layout = ({ children, headerRight, totalRestaurants }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'Sample' },
            { name: 'keywords', content: 'sample, something' },
          ]}
        >
          <html lang="it" />
        </Helmet>
        <Header headerRight={headerRight} totalRestaurants={totalRestaurants} />
        <main>{children}</main>
        <Footer />
        <CookieConsent
          buttonStyle={{ backgroundColor: Colors.RED, color: '#fff' }}
          buttonText="Acconsento"
        >
          Questo sito utilizza cookie tecnici e di profilazione anche di "terze
          parti" per i servizi offerti. Chiudendo questo banner acconsenti
          all'uso dei cookies.
        </CookieConsent>
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
