import React from 'react';
import { Link } from 'gatsby';
import { Flex, Box, Button, Link as L } from 'rebass';
import { Colors } from '../theme';

const logo = require('../images/logo.png');

const Header = ({ headerRight, totalRestaurants }) => (
  <header
    style={{
      backgroundColor: '#fff',
      position: 'sticky',
      top: 0,
      zIndex: 1000,
    }}
  >
    <div
      style={{
        borderBottomStyle: 'solid',
        borderBottomColor: '#ddd',
        borderBottomWidth: 1,
      }}
    >
      <div
        style={{
          margin: '0 auto',
          maxWidth: 1200,
        }}
      >
        <Flex
          px={[10, 0]}
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <Link to="/">
              <img
                alt="logo"
                style={{ maxHeight: 50, margin: 0, padding: 0 }}
                src={logo}
              />
            </Link>
          </Box>
          <Box className="hidden-xs" mx="auto" />
          {/* <Box mr={3} className="hidden-xs">
            <Link style={{ textDecoration: 'none' }} to="/inviaci-le-tue-foto">
              <Text color={Colors.RED}>
                INVIACI LE TUE FOTO
              </Text>
            </Link>
          </Box> */}
          <Box className="hidden-xs">
            <L
              mr={4}
              variant="headerLink"
              style={{ textDecoration: 'none', color: Colors.RED }}
              href="https://blog.mangiami.ch/"
            >
              BLOG
            </L>
          </Box>
          {headerRight === 'restaurants' && (
            <Box className="hidden-xs">
              <Link style={{ textDecoration: 'none' }} to="/ristoranti/">
                <Button variant="outline">
                  SCOPRI {totalRestaurants} RISTORANTI
                </Button>
              </Link>
            </Box>
          )}
          {headerRight === 'categories' && (
            <Box className="hidden-xs">
              <Link style={{ textDecoration: 'none' }} to="/esplora/">
                <Button variant="outline">ESPLORA PER CATEGORIA</Button>
              </Link>
            </Box>
          )}
        </Flex>
      </div>
    </div>
  </header>
);

export default Header;
