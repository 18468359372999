import React from 'react';
import { Link } from 'gatsby';
import {
  Flex, Box, Text, Image, Link as L,
} from 'rebass';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import { Colors } from '../theme';

const logo = require('../images/logo.png');
const logoTicino = require('../images/logo_ticino.png');
const logoDivoora = require('../images/logo_divoora.png');
const facebook = require('../images/facebook2.png');
const instagram = require('../images/instagram.png');

const styles = {
  menuItem: {
    display: 'inline-block',
    color: Colors.BLACK,
    textDecoration: 'none',
    margin: '0 10px',
  },
};

const Footer = () => (
  <div
    style={{
      borderTop: '1px solid #ddd',
      backgroundColor: '#f2f2f2',
      padding: '20px 40px',
      textAlign: 'center',
      flexDirection: 'row',
      justifyContent: 'space-around',
    }}
  >
    <div style={{ maxWidth: 1200, margin: '0 auto' }}>
      <Flex
        mx={[10, 10, 20]}
        my={20}
        alignItems="center"
        justifyContent="space-around"
        flexWrap="wrap"
      >
        <Flex flexDirection="column" width={[1, 1, 1, 1 / 4]}>
          <Box className="footer-logo">
            <Image my={2} alt="logo" style={{ maxHeight: 50 }} src={logo} />
          </Box>
          <Flex flexDirection="row" justifyContent="center" className="footer-socials">
            <a href="https://www.facebook.com/mangiamiapp/">
              <Image
                alt="facebook"
                style={{ maxHeight: 35, margin: 15, padding: 0 }}
                src={facebook}
              />
            </a>
            <a href="https://www.instagram.com/mangiamiapp/">
              <Image
                alt="instagram"
                style={{ maxHeight: 35, margin: 15, padding: 0 }}
                src={instagram}
              />
            </a>
          </Flex>
        </Flex>
        <Flex flexDirection="row" justifyContent="center" width={[1, 1, 1, 1 / 2]} my={4}>
          <Link py={3} className="menu-item" style={styles.menuItem} to="/terms-and-conditions/">
            Termini e condizioni
          </Link>
          <span className="hidden-sm hidden-xs">•</span>
          <Link py={3} className="menu-item" style={styles.menuItem} to="/privacy-policy/">
            Normativa sulla Privacy
          </Link>
        </Flex>
        <Flex
          width={[1, 1, 1, 1 / 4]}
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Text py={2} fontSize={1}>
            Partners
          </Text>
          <Box>
            <OutboundLink href="https://ticinoatavola.ch/">
              <Image px={2} width={100} src={logoTicino} alt="logo gastro ticino" />
            </OutboundLink>
            <OutboundLink href="https://divoora.ch/">
              <Image alt="logo divoora" px={2} width={[120, 160]} src={logoDivoora} />
            </OutboundLink>
          </Box>
        </Flex>
      </Flex>
      <span>
        © {new Date().getFullYear()} Hextech SA. Tutti i diritti riservati.{' '}
      </span>
      <Flex py={2} flexDirection="row" justifyContent="center" alignItems="center">
        <L color={Colors.RED} py={1} href="tel:+41765345547">+41 76 534 55 47</L>
        <Text mx={2}>•</Text>
        <L color={Colors.RED} py={1} href="mailto:team@mangiami.ch">team@mangiami.ch</L>
      </Flex>
    </div>
  </div>
);

export default Footer;
